/*
 * @Description:
 * @Autor: wangwangwang
 * @Date: 2024-01-27 19:10:40
 * @LastEditors: wangwangwang
 * @LastEditTime: 2024-03-22 10:21:30
 */
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        meta: {
            title: '首页'
        },
        component: () => import('../views/home/index.vue')
    },
    {
        path: '/about',
        name: 'about',
        meta: {
            title: '关于索贝'
        },
        component: () => import('../views/about/index.vue')
    },
    {
        path: '/newCenter',
        name: 'newCenter',
        meta: {
            title: '新闻中心'
        },
        component: () => import('../views/newCenter/index.vue')
    },
    {
        path: '/newDetails',
        name: 'newDetails',
        meta: {
            title: '新闻中心'
        },
        component: () => import('../views/newCenter/details.vue')
    },
    {
        path: '/productsSolutions',
        name: 'productsSolutions',
        meta: {
            title: '产品及解决方案'
        },
        component: () => import('../views/productsSolutions/index.vue')
    },
    {
        path: '/productsDetails',
        name: 'productsDetails',
        meta: {
            title: '产品及解决方案'
        },
        component: () => import('../views/productsSolutions/details.vue')
    },
    {
        path: '/appCaseDetails',
        name: 'appCaseDetails',
        meta: {
            title: '案例详情'
        },
        component: () => import('../views/productsSolutions/appCaseDetails.vue')
    },
    {
        path: '/awardsDetails',
        name: 'awardsDetails',
        meta: {
            title: '案例详情'
        },
        component: () => import('../views/awards/awardsDetails.vue')
    },
    {
        path: '/classicCase',
        name: 'classicCase',
        meta: {
            title: '经典案例'
        },
        component: () => import('../views/classicCase/index.vue')
    },
    {
        path: '/classicCaseDetails',
        name: 'classicCaseDetails',
        meta: {
            title: '经典案例详情'
        },
        component: () => import('../views/classicCase/details.vue')
    },
    {
        path: '/join',
        name: 'joinUs',
        meta: {
            title: '加入索贝'
        },
        component: () => import('../views/joinUs/index.vue')
    },
    {
        path: '/postdoctor',
        name: 'postDoctor',
        meta: {
            title: '博士后工作站'
        },
        component: () => import('../views/postdoctor/index.vue')
    },
    {
        path: '/content',
        name: 'content',
        meta: {
            title: '富文本'
        },
        component: () => import('../views/content/index.vue')
    },
    {
        path: '/privacy',
        name: 'privacy',
        meta: {
            title: '隐私政策'
        },
        component: () => import('../views/privacy/index.vue')
    },
    {
        path: '/search',
        name: 'search',
        meta: {
            title: '搜索'
        },
        component: () => import('../views/search/index.vue')
    },
    {
        path: '/zoologyDetails',
        name: 'zoologyDetails',
        meta: {
            title: '生态详情'
        },
        component: () => import('../views/zoologyDetails/index.vue')
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
});
export default router;
