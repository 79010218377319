/*
 * @Description:
 * @Autor: wangwangwang
 * @Date: 2024-01-27 19:10:40
 * @LastEditors: wangwangwang
 * @LastEditTime: 2024-01-30 15:21:34
 */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './style.css';
import 'animate.css';
import './common.css';
import './reset.css';
import './swiper.min.css';
import './swiper.min.js';
import './jquery-3.1.0.min.js';
import './common.js';
import WOW from 'wow.js';

new WOW({
    boxClass: 'wow', // 类名，在用户滚动时显示隐藏的框。
    animateClass: 'animate__animated', // 触发CSS动画的类名称
    offset: 70, // 定义浏览器视口底部与隐藏框顶部之间的距离。当用户滚动并到达此距离时，隐藏的框会显示出来。
    mobile: true, // 在移动设备上打开/关闭WOW.js。
    live: true // 在页面上同时检查新的WOW元素。
}).init();

Vue.config.productionTip = false;

import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
Vue.use(VueAwesomeSwiper);

Vue.filter('formatImageUrl', function (url) {
    if (url == null || url === '') return '';
    return url.replace('http://sobeycdn.cdbipei.com', 'https://www.sobey.com/asset');
});

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount('#app');
