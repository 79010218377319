import $ from './jquery-3.1.0.min.js';
var scrolltop = new Array();
var index = 0;
var scrollStatus = true;
scrolltop[0] = 0;

function start() {
    //.not('[data-isLoaded]')选中已加载的图片不需要重新加载
    $('#app img')
        .not('[data-isLoaded]')
        .each(function () {
            var $node = $(this);
            if (isShow($node)) {
                loadImg($node);
            }
        });
}

function isShow($node) {
    return $node.offset().top <= $(window).height() + $(window).scrollTop();
}

function loadImg($img) {
    //.attr(值)
    //.attr(属性名称,值)
    $img.attr('src', $img.attr('data-src'));
    $img.attr('data-isLoaded', 1);
}

function btnHide() {
    let len = $('.header-menu .sub-menu.active .sub-item').length;
    let active = $('.header-menu .sub-menu.active .warpper').attr(
        'data-active'
    );
    let total = $('.header-menu .sub-menu.active .warpper').attr('data-total');
    if (len > 7) {
        $('.header-menu .btn').fadeIn();
        if (active == 0) {
            $('.header-menu .btn.prev').hide();
        }
        if (active == total) {
            $('.header-menu .btn.next').hide();
        }
    }
}
function move(type) {
    let active = $('.header-menu .sub-menu.active .warpper').attr(
        'data-active'
    );
    if (type == 'add') {
        active++;
    } else {
        active--;
    }
    $('.header-menu .sub-menu.active .warpper').attr('data-active', active);
    let width = $('.header-menu .sub-menu.other').width();
    $('.header-menu .sub-menu.active.other .warpper').css(
        'transform',
        'translate3d(-' + active * width + 'px,0,0)'
    );
    $('.header-menu .sub-menu.other').scrollTop(0);
    btnHide();
}

$(function () {
    start();
    $('.header-menu .btn.next').on('click', function () {
        move('add');
    });
    $('.header-menu .btn.prev').on('click', function () {
        move('reduce');
    });
    $('.header-menu .sub-menu.other').each(function () {
        let len = $(this).find('.sub-item').length;
        $(this)
            .find('.warpper')
            .width(Math.ceil(len / 7) + '00%')
            .attr('data-active', 0)
            .attr('data-total', Math.floor(len / 7))
            .css('text-align', len < 7 ? 'center' : 'left');
    });
    $(window).on('scroll', function (e) {
        start();
        index++;
        scrolltop[index] = $(window).scrollTop();
        if (scrolltop[index] > scrolltop[index - 1]) {
            if ($(window).width() < 1024) return;
            $('.header .menu .item').removeClass('active');
            $('.header-menu .sub-menu').removeClass('active');
            $('.fixed-header').removeClass('show');
            $('.fixed-header').addClass('hide');
            $('.header-menu .sub-menu').removeClass('active');
            $('.header-menu').removeClass('show');
        } else {
            if (scrollStatus == true) {
                $('.fixed-header').removeClass('hide');
                $('.fixed-header').addClass('show');
            }
        }
    });
    $('.sticky.anchor .item').on('click', function () {
        if (!scrollStatus) {
            return false;
        }
        scrollStatus = false;
        let _this = $(this);
        $('html,body').animate(
            {
                scrollTop: $(_this.attr('href')).offset().top
            },
            500
        );
        $('.sticky .item').removeClass('active');
        $(this).addClass('active');
        setTimeout(function () {
            scrollStatus = true;
        }, 600);
    });
    $('.tab .item').on('click', function () {
        $('.tab .item').removeClass('active');
        $(this).addClass('active');
    });

    $(window).scroll(function () {
        let scrollTop = $(this).scrollTop();
        console.log('scrollTop: ', scrollTop);
        console.log('$(window).height(): ', $(window).height());
        if (scrollTop >= $(window).height()) {
            $('.scroll-top').fadeIn();
        } else {
            $('.scroll-top').fadeOut();
        }
        if (scrollTop == 0) {
            $('.fixed-header').removeClass('hide');
            $('.fixed-header').addClass('show');
        }
    });
    $('.footer .menu .item .title').on('click', function () {
        $('.footer .menu .item .sub').slideUp();
        $(this)
            .parent('.item')
            .siblings('.item')
            .find('.title')
            .removeClass('active');
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $(this).siblings('.sub').slideUp();
        } else {
            $(this).addClass('active');
            $(this).siblings('.sub').slideDown();
        }
    });
    $('.footer .join .icon').on('click', function () {
        $('.footer .code-mask').css('display', 'flex');
    });
    $('.footer .code-mask .content .close').on('click', function () {
        $('.footer .code-mask').css('display', 'none');
    });
    $('.header .menu .icon.menu-btn').on('click', function () {
        $(this).toggleClass('active');
        $('.header-menu').toggleClass('show');
        $('.header-menu .sub-menu .sub-item .sub-title').removeClass('active');
        $(
            '.header-menu .sub-menu .sub-item > ul, .header-menu .sub-menu .sub-item .sub-child'
        ).slideUp();
        $('.header-menu .menu .menu-item').removeClass('active');
        $('.header-menu .sub-menu').removeClass('active');
        $('.scroll-top').fadeOut();
        if ($('.header-menu').hasClass('show')) {
            if ($(window).width() < 1024) {
                $('body').css({ overflow: 'hidden' });
            }
        } else {
            if ($(window).width() < 1024) {
                $('body').css({ overflow: 'auto' });
            }
        }
    });
    $('.header-menu .menu .menu-item').on('click', function () {
        $('.header-menu .menu .menu-item').removeClass('active');
        $('.header-menu .sub-menu').removeClass('active');
        $('.header-menu').removeClass('show');
        $(this).addClass('active');
        let id = $(this).data('id');
        $('.header-menu .sub-menu').each(function () {
            let itemId = $(this).data('id');
            if (itemId == id) {
                $('.search').removeClass('show');
                $(this).addClass('active');
                $('.header-menu').addClass('show');
            }
        });
    });
    $('.header-menu .sub-menu .sub-item .sub-title').on('click', function () {
        if ($(window).width() < 1024) {
            $(
                '.header-menu .sub-menu .sub-item > ul, .header-menu .sub-menu .sub-item .sub-child'
            ).slideUp();
            $(this)
                .parent('.sub-item')
                .siblings('.sub-item')
                .find('.sub-title')
                .removeClass('active');
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).siblings('ul, .sub-child').slideUp();
            } else {
                $(this).addClass('active');
                $(this).siblings('ul, .sub-child').slideDown();
            }
        }
    });
});
function scrollBottom(callBack) {
    $(window).scroll(function () {
        if (
            $(window).scrollTop() + $(window).height() + 100 >=
            $(document).height()
        ) {
            callBack();
        }
    });
}
